<button
  *ngIf="title && collapsible"
  class="op-sidemenu--title"
  type="button"
  (click)="toggleCollapsed()"
>
  {{ title }}
  <span class="icon-small"
        [ngClass]="collapsed ? 'icon-arrow-down1' : 'icon-arrow-up1'"
        aria-hidden="true">
  </span>
</button>

<div
  *ngIf="title && !collapsible"
  class="op-sidemenu--title"
>
  {{ title }}
</div>

<ul
  class="op-sidemenu--items"
  [ngClass]="{'op-sidemenu--items_collapsed' : collapsed, 'searchable-menu--search-ul': searchable}"
>
  <li
    *ngFor="let item of items"
    class="op-sidemenu--item"
    data-test-selector="op-sidemenu--item"
  >
    <op-sidemenu
      *ngIf="item.children && item.children.length"
      [title]="item.title"
      [items]="item.children"
      [collapsible]="item.collapsible"
    ></op-sidemenu>

    <a
      *ngIf="!item.children && item.uiSref"
      class="op-sidemenu--item-action"
      [class]="{ 'op-sidemenu--item-action_with_icon': !!item.icon }"
      uiSrefActive="op-sidemenu--item-action_active-child"
      uiSrefActiveEq="op-sidemenu--item-action_active"
      [attr.data-test-selector]="'op-sidemenu--item-action--' + item.title.split(' ').join('')"
      [uiSref]="item.uiSref"
      [uiParams]="item.uiParams"
      [uiOptions]="item.uiOptions"
    >
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item }"></ng-container>
    </a>

    <a
      *ngIf="!item.children && item.href"
      class="op-sidemenu--item-action"
      [class]="{ 'op-sidemenu--item-action_with_icon': !!item.icon }"
      [attr.data-test-selector]="'op-sidemenu--item-action--' + item.title.split(' ').join('')"
      [href]="item.href"
    >
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item }"></ng-container>
    </a>

  </li>
</ul>

<ng-template
  #itemTemplate
  let-item="item"
>
  <span
    *ngIf="item.icon"
    class="op-sidemenu--item-icon"
    [class]="'icon-' + item.icon"
  ></span>
  <span
    class="op-sidemenu--item-title"
  >{{ item.title }}
    <span class="op-sidemenu--enterprise-badge spot-icon spot-icon_enterprise-addons" *ngIf="noEEToken && item.isEnterprise"></span>
  </span>
  <span class="op-bubble op-bubble_alt_highlighting" *ngIf="item.count">{{ item.count }}</span>
</ng-template>
